import React, { useContext } from "react";

import { ACTION_TYPE, dragNDropContext, DragNDropContext } from "./DragNDropContext";
import "./drag-n-drop.scss";
import { convertSize } from "../../services/tools";

import { useTranslation } from "react-i18next";

class CustomError extends Error {
    title: string | undefined;

    constructor(message: string, title: string | undefined) {
        super(message);
        this.title = title;
        Object.setPrototypeOf(this, CustomError.prototype);
    }
}

const dispatchError = (
    context: DragNDropContext,
    payload: {
        title?: string;
        message: string;
    }
) => {
    context.dispatch({
        type: ACTION_TYPE.SET_ERROR,
        payload: payload
    });
};

const dispatchDragging = (context: DragNDropContext, payload: boolean) => {
    context.dispatch({
        type: ACTION_TYPE.SET_DRAG,
        payload: payload
    });
};

const dispatchSetFile = (context: DragNDropContext, payload: File) => {
    context.dispatch({
        type: ACTION_TYPE.SET_FILE,
        payload: payload
    });
};

type DragNDropProps = {
    onDrop: (file: File) => void;
    onDragEnter?: (e: React.DragEvent<HTMLElement>) => void;
    onDragLeave?: (e: React.DragEvent<HTMLElement>) => void;
    children: React.ReactNode;
    disabled?: boolean;
    id?: string;
};

const DragNDrop: React.FC<DragNDropProps> = ({
    onDrop,
    onDragEnter,
    onDragLeave,
    children,
    id,
    disabled = false
}) => {
    if (React.Children.count(children) === 0) {
        throw Error("The DragNDrop component MUST have children");
    }
    const { t } = useTranslation();

    const context = useContext(dragNDropContext);

    const overrideEventDefaults = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const verifyFileOnDrag = (e: React.DragEvent<HTMLElement>): File => {
        if (!e.dataTransfer || !e.dataTransfer.files) {
            throw new CustomError(
                t("error.dragAndDrop-file-failed-not-found"),
                t("error.dragAndDrop-file-failed-title")
            );
        }
        if (e.dataTransfer.files.length > 1) {
            throw new CustomError(
                t("error.dragAndDrop-file-failed-only-one"),
                t("error.dragAndDrop-file-failed-title")
            );
        }
        if (e.dataTransfer.files.length === 0) {
            throw new CustomError(
                t("error.dragAndDrop-file-failed-at-least-one"),
                t("error.dragAndDrop-file-failed-title")
            );
        }
        const file = e.dataTransfer.files[0];
        if (file.type === "") {
            throw new CustomError(
                t("error.dragAndDrop-file-failed-folder-not-supported"),
                t("error.dragAndDrop-file-failed-title")
            );
        }

        if (context.state.accepts !== "*" && !context.state.accepts.includes(file.type)) {
            throw new CustomError(                
                t("error.dragAndDrop-file-failed-not-valid-type", { extension: file.type }),
                t("error.dragAndDrop-file-failed-title")
            );
        }
        if (context.state.maxSize && file?.size && file.size > context.state.maxSize) {
            throw new CustomError(                
                t("error.dragAndDrop-file-failed-size-limit", { maxSize: convertSize(context.state.maxSize) }),
                t("error.dragAndDrop-file-failed-title")
            );
        }
        return file;
    };

    const verifyFileOnClick = (e: React.ChangeEvent<HTMLInputElement>): File => {
        if (!e.target.files) {
            throw new CustomError(
                t("error.dragAndDrop-file-failed-not-found"),
                t("error.dragAndDrop-file-failed-title")
            );
        }
        if (e.target.files.length > 1) {
            throw new CustomError(
                t("error.dragAndDrop-file-failed-only-one"),
                t("error.dragAndDrop-file-failed-title")
            );
        }
        if (e.target.files.length === 0) {
            throw new CustomError(
                t("error.dragAndDrop-file-failed-at-least-one"),
                t("error.dragAndDrop-file-failed-title")
            );
        }
        const file = e.target.files[0];
        if (Array.isArray(context.state.accepts) && !context.state.accepts.includes(file.type)) {
            throw new CustomError(                
                t("error.dragAndDrop-file-failed-not-valid-type", { extension: file.type }),
                t("error.dragAndDrop-file-failed-title")
            );
        }
        if (context.state.maxSize && file?.size && file.size > context.state.maxSize) {
            throw new CustomError(                
                t("error.dragAndDrop-file-failed-size-limit", { maxSize: convertSize(context.state.maxSize) }),
                t("error.dragAndDrop-file-failed-title")
            );
        }
        return file;
    };

    const handleDrop = (e: React.DragEvent<HTMLElement>): void => {
        overrideEventDefaults(e);
        let file: File;

        if (context.state.file) {
            return;
        }

        try {
            file = verifyFileOnDrag(e);
        } catch (error) {
            if (error instanceof CustomError) {
                dispatchError(context, { title: error?.title, message: error.message });
            } else if (error instanceof Error) {
                dispatchError(context, { message: error.message });
            } else {
                dispatchError(context, { message: t("error.internalError") });
            }
            dispatchDragging(context, false);
            e.dataTransfer.clearData();
            return;
        }
        dispatchSetFile(context, file);
        dispatchDragging(context, false);
        e.dataTransfer.clearData();
        onDrop(file);
    };

    const handleDragEnter = (e: React.DragEvent<HTMLElement>) => {
        overrideEventDefaults(e);
        dispatchDragging(context, true);
        if (onDragEnter) {
            return onDragEnter(e);
        }
    };

    const handleDragLeave = (e: React.DragEvent<HTMLElement>) => {
        overrideEventDefaults(e);
        dispatchDragging(context, false);
        if (onDragLeave) {
            return onDragLeave(e);
        }
    };

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let file: File;

        if (context.state.file) {
            return;
        }

        try {
            file = verifyFileOnClick(e);
        } catch (error) {
            if (error instanceof CustomError) {
                dispatchError(context, { title: error?.title, message: error.message });
            } else if (error instanceof Error) {
                dispatchError(context, { message: error.message });
            } else {
                dispatchError(context, { message: t("error.internalError") });
            }

            dispatchDragging(context, false);
            return;
        }
        dispatchSetFile(context, file);
        dispatchDragging(context, false);
        return onDrop(file);
    };

    return (
        <>
            <input
                ref={context.state.inputRef}
                type="file"
                id={`file-${id}`}
                accept={`${context.state.accepts}`}
                onChange={onChangeInput}
                className="drag-n-drop__input"
                disabled={disabled || !!context.state.file}
            />
            <label
                id={id}
                htmlFor={`file-${id}`}
                onDrop={handleDrop}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={overrideEventDefaults}
                className="drag-n-drop__label"
            >
                {children}
            </label>
        </>
    );
};

export default DragNDrop;
